<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="rentalAgreementStep1"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="moveToStep2"
  >
    <div class="tw-mb-8 tw-pb-4 tw-border-b">
      <h2>Verhuurovereenkomst</h2>
      <div class="tw-grid md:tw-grid-cols-3 tw-gap-x-4">
        <FormulateInput
          name="agreement_signature_date"
          type="date"
          :value="today.date"
          label="Datum ondertekening overeenkomst"
          outer-class="md:tw-my-0"
        />
        <FormulateInput
          name="rental_start_date"
          type="date"
          label="Datum ingang huurovereenkomst"
          outer-class="md:tw-my-0 tw-my-4"
        />
      </div>

      <div class="tw-grid md:tw-grid-cols-3 tw-gap-x-4 md:tw-my-4">
        <FormulateInput
          type="number"
          name="price"
          label="Maandelijkse huurprijs"
          placeholder="Maandelijkse huurprijs"
          lang="nl"
          step="0.01"
          min="0"
          validation="bail|required|number|min:0"
          data-lpignore="true"
          outer-class="tw-my-0"
          @change="recalculateCommissionFixOut"
        />

        <FormulateInput
          name="rental_management_type"
          type="select"
          label="Verhuurformule"
          placeholder="Kies een verhuurformule"
          validation="required"
          :options="RENTAL_MANAGEMENT_TYPES"
          outer-class="md:tw-my-0"
          @change="setThirdPartyAccount"
        />
      </div>

      <div class="tw-grid md:tw-grid-cols-3 tw-gap-x-4 md:tw-my-4">
        <FormulateInput
          name="rental_term"
          type="select"
          label="Duurtijd huurcontract"
          placeholder="Kies een duurtijd"
          validation="required"
          :options="rentalTerms"
          outer-class="md:tw-my-0"
        />

        <FormulateInput
          type="file"
          name="rental_agreement_file"
          label="Ondertekend document"
          :uploader="uploadFile"
          :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
          :file-class="['tw-h-10 !tw-mt-1']"
          element-class="formulate-input-element formulate-input-element--file"
          wrapper-class="formulate-input-wrapper"
          outer-class="formulate-input"
        >
          <template #file="context">
            <BaseFileUploadDisplay
              v-bind="context"
              :show-remove-file="true"
            />
          </template>
        </FormulateInput>
      </div>
    </div>

    <div class="tw-mb-8 tw-pb-4 tw-border-b">
      <h2>Te ontvangen bij intrede (derdenrekening)</h2>
      <div class="tw-grid md:tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          type="number"
          name="first_month_rent"
          label="Eerste maand huur"
          placeholder="Eerste maand huur"
          lang="nl"
          step="0.01"
          min="0"
          validation="bail|required|number|min:0"
          data-lpignore="true"
          outer-class="tw-my-0"
        />

        <FormulateInput
          type="number"
          name="monthly_costs_or_commission"
          label="Eenmalige kost bij intrede"
          placeholder="Eenmalige kost bij intrede"
          lang="nl"
          step="0.01"
          min="0"
          validation="bail|number|min:0"
          data-lpignore="true"
          outer-class="tw-my-0"
        >
          <template #label="{ id }">
            <label :for="id" class="formulate-label">
              Eenmalige kost bij intrede
            </label>
            <Tooltip class="tw-ml-2 tw-inline-block">
              <i class="fas fa-info-circle" />
              <template #popper>
                Alle kosten: provisie, EGW, verhuiskost, badge, abonnement parking,…
              </template>
            </Tooltip>
          </template>
        </FormulateInput>
      </div>
      <div class="tw-mt-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          type="number"
          name="inventory_of_fixtures_tenant_amount"
          label="Deel huurder plaatsbeschrijving"
          placeholder="Deel huurder plaatsbeschrijving"
          lang="nl"
          step="0.01"
          min="0"
          validation="bail|number|min:0"
          data-lpignore="true"
          outer-class="tw-my-0"
        />

        <FormulateInput
          type="number"
          name="rental_dossier_costs"
          label="Dossierkosten (bedrijfsvastgoed)"
          placeholder="Dossierkosten (bedrijfsvastgoed)"
          lang="nl"
          step="0.01"
          min="0"
          validation="bail|number|min:0"
          data-lpignore="true"
          outer-class="tw-my-0"
        />
      </div>
      <div class="tw-mt-4 md:tw-w-[66%]">
        <FormulateInput
          type="select"
          name="third_party_account"
          label="Derdenrekening"
          :options="thirdPartyAccountOptions"
          :disabled="thirdPartyAccountOptions.length <= 1"
          placeholder="Derdenrekening"
          validation="required"
          outer-class="tw-my-0"
        />
      </div>

      <div class="tw-font-bold tw-my-4">
        Verwacht bedrag op derdenrekening: {{ currency(totalThirdPartyAmount) }}
      </div>
    </div>

    <div class="tw-mb-8 tw-pb-4 tw-border-b">
      <h2>Ereloon</h2>
      <FormulateInput
        type="checkbox"
        name="should_deduct_rental_fee_from_first_months_rent"
        label="Afhouden ereloon van eerste maanden huur"
      />
      <div class="tw-grid md:tw-grid-cols-3 tw-gap-4 tw-mb-4">
        <FormulateInput
          type="select"
          name="commission_type_out"
          label="Commissietype"
          :options="commissionTypeOptions"
          outer-class="tw-my-0"
          @change="recalculateCommissionFixOut"
        />
        <FormulateInput
          type="number"
          name="commission_fix_out"
          label="Ereloon"
          placeholder="Ereloon"
          lang="nl"
          step="0.01"
          min="0"
          validation="bail|required|number|min:0"
          :disabled="!commissionFixOutEnabled"
          data-lpignore="true"
          outer-class="tw-my-0"
        />
      </div>
    </div>

    <div class="tw-mb-8 tw-pb-4">
      <h2>Betrokken kantoren en poules</h2>
      <TransactionInvolvementData
        ref="transactionInvolvements"
        context="rentalAgreement"
        :property-id="propertyId"
      />
    </div>

    <FormulateErrors class="tw-text-right" />

    <div class="tw-mt-4 tw-flex tw-flex-wrap tw-gap-4 tw-justify-end">
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        outer-class="tw-my-0"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
          ]"
        />
        Volgende stap
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import { Tooltip } from 'floating-vue'
import { currency, parseFileObj, today } from '@/utils/helpers'
import { RENTAL_MANAGEMENT_TYPES, USAGE_TYPES } from '@/forms/selectOptions'
import {
  uploadFile,
  createPropertyFile,
  createPropertyFileFolder
} from '@/services/properties'

import TransactionInvolvementData from '@/components/transactions/TransactionInvolvementData'

export default {
  name: 'WizardRentalAgreementStep1',
  components: {
    Tooltip,
    TransactionInvolvementData
  },
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    },
    initialValues: {
      type: Object,
      required: true
    },
    thirdPartyAccounts: {
      type: Array,
      required: true
    },
    rentalTerms: {
      type: Array,
      required: true
    }
  },
  constants: {
    CURRENT_STEP: 1,
    RENTAL_MANAGEMENT_TYPES,
    USAGE_TYPES,
    COMMISSION_TYPE_MONTHLY_RENT: 6,
    COMMISSION_TYPE_ANNUAL_RENT_5_PERCENT: 17,
    COMMISSION_TYPE_ANNUAL_RENT_10_PERCENT: 15,
    COMMISSION_TYPE_ANNUAL_RENT_15_PERCENT: 9,
    COMMISSION_TYPE_OTHER: 12,
    COMMISSION_TYPE_HALF_MONTH: 16,
    today
  },
  data () {
    return {
      values: {},
      folderId: null
    }
  },
  computed: {
    totalThirdPartyAmount () {
      const sumValues = [
        this.values.first_month_rent,
        this.values.monthly_costs_or_commission,
        this.values.inventory_of_fixtures_tenant_amount,
        this.values.rental_dossier_costs
      ]
      const result = sumValues.filter(item => item !== undefined && item !== null).reduce((acc, val) => acc + parseFloat(val), 0)
      return result.toFixed(2)
    },
    commissionTypeOptions () {
      return [
        { value: this.COMMISSION_TYPE_MONTHLY_RENT, label: 'Één maand huur' },
        { value: this.COMMISSION_TYPE_ANNUAL_RENT_5_PERCENT, label: '5% op jaarhuur' },
        { value: this.COMMISSION_TYPE_ANNUAL_RENT_10_PERCENT, label: '10% op jaarhuur' },
        { value: this.COMMISSION_TYPE_ANNUAL_RENT_15_PERCENT, label: '15% op jaarhuur' },
        { value: this.COMMISSION_TYPE_OTHER, label: 'Andere' }
      ]
    },
    commissionFixOutEnabled () {
      return parseInt(this.values.commission_type_out) === this.COMMISSION_TYPE_OTHER
    },
    thirdPartyAccountOptions () {
      let accounts = []
      if (this.values.rental_management_type === null) {
        accounts = this.thirdPartyAccounts
      } else if (parseInt(this.values.rental_management_type) === 0) {
        // If rental management type is 0 (classic rental), only show the rental third party accounts
        accounts = this.thirdPartyAccounts.filter(account => account.transaction_types.includes(3))
      } else {
        // Else show the rental management third party accounts
        accounts = this.thirdPartyAccounts.filter(account => account.transaction_types.includes(5))
      }
      return accounts.map(thirdPartyAccount => {
        return {
          value: thirdPartyAccount.id,
          label: thirdPartyAccount.display_name
        }
      })
    }
  },
  created () {
    this.setInitialValues()
  },
  methods: {
    currency,
    moveToStep2 (data) {
      data.third_party_amount = this.totalThirdPartyAmount
      data.involvements = this.$refs.transactionInvolvements ? this.$refs.transactionInvolvements.getInvolvements() : []
      this.$emit('finished', data, this.CURRENT_STEP)
      return data
    },
    recalculateCommissionFixOut () {
      const selectedCommissionTypeId = parseInt(this.values.commission_type_out)
      if (selectedCommissionTypeId === this.COMMISSION_TYPE_MONTHLY_RENT) {
        this.$set(this.values, 'commission_fix_out', this.values.price)
      }
      if (selectedCommissionTypeId === this.COMMISSION_TYPE_HALF_MONTH) {
        this.$set(this.values, 'commission_fix_out', this.values.price / 2)
      }
      if (selectedCommissionTypeId === this.COMMISSION_TYPE_ANNUAL_RENT_5_PERCENT) {
        const newCommission = 0.05 * (this.values.price * 12)
        this.$set(this.values, 'commission_fix_out', newCommission)
      }
      if (selectedCommissionTypeId === this.COMMISSION_TYPE_ANNUAL_RENT_10_PERCENT) {
        const newCommission = 0.1 * (this.values.price * 12)
        this.$set(this.values, 'commission_fix_out', newCommission)
      }
      if (selectedCommissionTypeId === this.COMMISSION_TYPE_ANNUAL_RENT_15_PERCENT) {
        const newCommission = 0.15 * (this.values.price * 12)
        this.$set(this.values, 'commission_fix_out', newCommission)
      }
    },
    setThirdPartyAccount () {
      if (this.values.rental_management_type === null) return
      this.$set(this.values, 'third_party_account', this.thirdPartyAccountOptions?.[0]?.value)
    },
    setRentalTerm () {
      if (this.values.rental_term) return
      const defaultRentalTerm = this.rentalTerms.find(rentalTerm => rentalTerm.is_default)
      this.$set(this.values, 'rental_term', defaultRentalTerm?.value)
    },
    setInitialValues () {
      this.values = this.initialValues
      this.folderId = this.initialValues?.agreements_folder_id
      this.$set(this.values, 'first_month_rent', this.initialValues?.price)
      this.setThirdPartyAccount()
      this.setRentalTerm()
    },
    async createAgreementsFolder () {
      const response = await createPropertyFileFolder(this.propertyId, { name: 'Overeenkomsten' })
      // We don't want to call this method again and again, so we store the value of the folderId
      this.folderId = response.data.id
      return response.data.id
    },
    async uploadFile (file, progress) {
      try {
        progress(0)
        const file_type_key = 'signed_rental_agreement'
        const formData = new FormData()
        formData.append('file', file)
        progress(25)
        const { key, filename } = (await uploadFile(this.propertyId, formData)).data
        progress(50)
        const folder_id = this.folderId || await this.createAgreementsFolder()
        progress(75)
        const payload = { key, filename, file_type_key, folder_id }
        const response = await createPropertyFile(this.propertyId, payload)
        progress(100)
        const parsedFile = parseFileObj(response.data)
        return [parsedFile]
      } catch (error) {
        this.$formulate.handle(error, 'rentalAgreementStep1')
      }
    }
  }
}
</script>
